import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'post',
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
    },
  },
  {
    path: '/tag',
    name: 'tag',
    component: () => import('@/views/Tag.vue'),
    meta: {
      layout: 'content',
      title: 'Tags',
      requireAuth: true
    },
  },

  {
    path: '/author',
    name: 'author',
    component: () => import('@/views/Author.vue'),
    meta: {
      layout: 'content',
      title: 'Authors',
      requireAuth: true
    },
  },

  {
    path: '/post',
    name: 'post',
    component: () => import('@/views/Post.vue'),
    meta: {
      layout: 'content',
      title: 'Posts',
      requireAuth: true
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})


const DEFAULT_TITLE = 'Tagge AC | ';
router.beforeEach(async (to, from, next) => {
  document.title = DEFAULT_TITLE + (to.meta.title || '');
  if (to.meta.requireAuth) {
    if (localStorage.getItem('authAccount')) {
      next();
    } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        });
    }
  } else {
    next()
  }
});

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
