<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
        <v-icon size="24px">{{icons.mdiAccountOutline}}</v-icon>
        </v-avatar>
    </template>
    <v-list v-if="account">
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-icon size="24px">{{icons.mdiAccountOutline}}</v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{account.userName}}
          </span>
          <small class="text--disabled text-capitalize">{{account.email}}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
    import { mdiAccountOutline,mdiLogoutVariant,} from '@mdi/js';
    import {managerLogout} from '@/request/manager';

    export default {
        methods: {
          async logout() {
            await managerLogout().then((res) => {
              console.log(res);
              if (res.status === 0) {
                this.$store.commit('setAuthAccount', null);
                this.$router.push('/login');
              } else {
                this.$q.error(res.message);
              }
            }).catch((err) => {
                this.$q.error(res.message);
            });
          },
        },
        data () {
            return {
                account: null,
                icons: {
                    mdiAccountOutline, 
                    mdiLogoutVariant,
                }
            }
        },
        watch: {
            '$store.state.authAccount': function () {
                if (!this.$store.state.authAccount) {
                    this.account = null
                } else {
                    this.account = this.$store.state.authAccount
                }
            }
        },
        mounted: async function() {
            this.account = this.$store.state.authAccount;
        }
    }
</script>


<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
