import axios from 'axios'
import QS from 'qs'

// axios.defaults.baseURL = 'http://localhost:3003'

axios.defaults.baseURL = '/'

// set timeout
axios.defaults.timeout = 10000

// set post header
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// set response interception
axios.interceptors.response.use(
  response => {    
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  },
  error => Promise.reject(error.response),
)

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params))
      .then(res => {
        if(res && res.data && res.data.status === 402) {
          // remove local storage
          localStorage.removeItem('authAccount');
          //refresh current page
          window.location.reload();
        }
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
  })
}

export function post_raw(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
  })
}
