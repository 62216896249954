import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import snackbar from './snackbar'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authAccount : null
  },
  mutations: {
    setAuthAccount(state, account) {
        state.authAccount = account;
        //save to local storage
        if (account) {
          localStorage.setItem('authAccount', JSON.stringify(account));
        }else {
          localStorage.removeItem('authAccount');
        }
    }
  },
  actions: {
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    snackbar,
  },
})
