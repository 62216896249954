
const moment = require('moment')

export default {
  
  formatTimestamp(timestamp) {
    return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm')
  },

  shortAddress(address) {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  },
}