import { mdiAccount, mdiNewspaper, mdiTag } from '@mdi/js'

export default [
  {
    title: 'Tag',
    icon: mdiTag,
    to: 'tag',
  },
  {
    title: 'Author',
    icon: mdiAccount,
    to: 'author',
  },
  {
    title: 'Post',
    icon: mdiNewspaper,
    to: 'post',
  },
]
