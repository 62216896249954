import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import snackbarPlugin from './plugins/snackbar'
import router from './router'
import store from './store'
import GlobalUtil from './utils/global'
import VueTimeago from 'vue-timeago'

Vue.prototype.$tools = GlobalUtil;
Vue.use(snackbarPlugin, { store })

Vue.config.productionTip = false


Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

