const snackbarPlugin = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error('Please provide vuex store.')
    }
    Vue.prototype.$q = {
      showMessage({ content = '', color = '' }) {
        store.commit(
          'snackbar/showMessage',
          { content, color },
          { root: true },
        )
      },
      error(content) {
        store.commit(
          'snackbar/showMessage',
          { content, color: 'error' },
          { root: true },
        )
      },
      warning(content) {
        store.commit(
          'snackbar/showMessage',
          { content, color: 'warning' },
          { root: true },
        )
      },
      info(content) {
        store.commit(
          'snackbar/showMessage',
          { content, color: 'info' },
          { root: true },
        )
      },
      success(content) {
        store.commit(
          'snackbar/showMessage',
          { content, color: 'success' },
          { root: true },
        )
      },
    }
  },
}
export default snackbarPlugin
